<template>
    <div class="hexiaorecords">
        <div class="top-order">
            <p>
                <span>{{ count.total }}</span>
                <i>单</i>
            </p>
            <p>今日预约</p>
        </div>
        <!-- 筛选区 -->
        <div class="tab-box">
            <div class="tab">
                <div :class="['item',{'active':Tab == 0}]" @click="changeTab(0)">今日</div>
                <div :class="['item',{'active':Tab == 1}]" @click="changeTab(1)">筛选日期查看</div>
                <div :class="['item',{'active':Tab == 2}]" @click="changeTab(2)">手机号搜索</div>
            </div>
        </div>
        <!-- 日期筛选 -->
        <div class="date-box" v-if="Tab == 1">
            <span>从</span>
            <div class="time" @click="showCalendar = true">{{startDate == ''?'开始日期':startDate}}</div>
            <span>到</span>
            <div class="time" @click="showCalendar = true">{{endDate == ''?'结束日期':endDate}}</div>
            <span class="read" @click="dateSearch">查看</span>
        </div>
        <!-- 手机号筛选 -->
        <div class="code-search" v-if="Tab == 2">
            <input type="number" v-model="phone" placeholder="请输入手机号进行搜索" class="input" />
            <span @click="search">搜索</span>
        </div>
        <!-- 核销列表 -->
        <template v-if="isLoad">
            <div :class="['tabbar',{'tabbar-h-1': Tab == 1},{'tabbar-h-2': Tab == 2}]" v-if="list.length > 0">
                <van-list v-model="loading" :finished="finished" finished-text="到底啦〜想不到你看到了这里^_^"
                    @load="verificationList">
                    <!-- <p>预约明细</p> -->
                    <write-off-item v-for="(item, index) in list" :key="index" :item="item" from="appointment">
                    </write-off-item>
                </van-list>
            </div>
            <no-data v-else content="暂无预约记录"></no-data>
        </template>
        <!-- 开始结束日期选择 -->
        <van-calendar v-model="showCalendar" type="range" :min-date="minDate" confirm-text="完成"
            confirm-disabled-text="请选择结束时间" :default-date="[new Date()]" :allow-same-day="false" color="#E04F29"
            @confirm="onConfirm" />
    </div>
</template>

<script>
    import {
        shopReserveTotal,
        shopReserveList
    } from "@/http/api";
    import WriteOffItem from "./WriteOffChildren/WriteOffItem";
    export default {
        components: {
            WriteOffItem
        },
        data() {
            return {
                Tab: 0,
                startDate: "",
                endDate: "",
                pages: 1,
                finished: false,
                isLoad: false,
                loading: false,
                list: [],
                count: {},
                showCalendar: false,
                date: "",
                minDate: new Date(2020, 0, 1),
                maxDate: new Date(),
                phone: "",
                shopUserInfo: {}
            };
        },
        beforeCreate() {
            if (!this.$LStorage.getItem("shopUserInfo")) {
                this.$router.replace({
                    path: "/merchatlogin"
                });
            }
        },
        mounted() {
            this.shopUserInfo = this.$LStorage.getItem("shopUserInfo");     
            this.verificationList();
            this.getStatistics();
        },
        methods: {
            // 筛选切换
            changeTab(tab) {
                this.Tab = tab;
                this.startDate = "";
                this.endDate = "";
                if (tab == 0) {
                    this.pages = 1;
                    this.finished = true;
                    this.isLoad = false;
                    this.list = [];
                    this.verificationList();

                    this.count = {};
                    this.getStatistics();
                }
            },
            //日期格式处理
            formatDate(date) {
                let year = date.getFullYear(),
                    month = ("0" + (date.getMonth() + 1)).slice(-2),
                    day = ("0" + date.getDate()).slice(-2);
                return year + "-" + month + "-" + day;
            },
            // 日期选择完成
            onConfirm(date) {
                const [start, end] = date;
                this.startDate = this.formatDate(start);
                this.endDate = this.formatDate(end);
                this.showCalendar = false;
            },
            //日期筛选
            dateSearch() {
                if (this.startDate == "" || this.endDate == "") {
                    this.$toast("请选择时间");
                    return;
                } else {
                    this.pages = 1;
                    this.finished = false;
                    this.isLoad = false;
                    this.list = [];
                    this.verificationList();
                    this.count = {};
                    this.getStatistics();
                }
            },
            //搜索
            search() {
                let key = this.phone;
                if (key == "") {
                    this.$toast("请输入手机号进行搜索");
                    return;
                } else if (key.length != 11) {
                    this.$toast("请输入11位手机号");
                    return;
                } else {
                    this.pages = 1;
                    this.finished = false;
                    this.isLoad = false;
                    this.list = [];
                    this.verificationList();
                    this.count = {};
                    this.getStatistics();
                }
            },
            //获取预约记录列表
            async verificationList() {
                const res = await shopReserveList({
                    data: {
                        reserve_shop_id: this.shopUserInfo.shop_id,
                        pages: this.pages,
                        sort_type: this.Tab,
                        start_date: this.startDate,
                        end_date: this.endDate,
                        reserve_phone: this.phone
                    }
                });
                this.isLoad = true;
                this.loading = false;
                if (res.data.length > 0) {
                    this.pages++;
                    this.list = this.list.concat(res.data);
                    this.finished = false;
                } else {
                    this.finished = true;
                }
            },
            //商家预约记录数据统计
            async getStatistics() {
                const res = await shopReserveTotal({
                    data: {
                        reserve_shop_id: this.shopUserInfo.shop_id,
                        sort_type: this.Tab,
                        start_date: this.startDate,
                        end_date: this.endDate,
                        reserve_phone: this.phone
                    }
                });
                this.count = res.data;
            }
        }
    };
</script>

<style lang="scss">
    .hexiaorecords {
        width: 100%;
        // height: 100vh;
        background: #f6f6f6;
        display: flex;
        flex-direction: column;
        font-size: 0.26rem;

        .top-order {
            background-color: #ff5400;
            height: 1.46rem;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            p {
                font-size: 0.26rem;
                color: #ffffff;

                span {
                    font-size: 0.48rem;
                }

                i {
                    font-size: 0.24rem;
                }
            }
        }

        .tab-box {
            padding: 0 0.24rem;
            box-sizing: border-box;
            width: 100%;
            height: 1.2rem;
            background-color: #ffffff;
            display: flex;
            align-items: center;

            .tab {
                display: flex;
                align-items: center;
                justify-content: space-around;
                background-color: #f5f5f5;
                width: 100%;
                height: 0.68rem;
                border-radius: 0.34rem;

                .item {
                    width: 1.84rem;
                    height: 0.6rem;
                    line-height: 0.6rem;
                    text-align: center;
                    color: #b1b1b1;
                }

                .active {
                    background-color: #ffffff;
                    border-radius: 0.3rem;
                    color: #000000;
                }
            }
        }

        .date-box {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 1rem;
            font-size: 0.28rem;
            color: #333333;
            background-color: #ffffff;

            .time {
                width: 1.93rem;
                height: 0.5rem;
                line-height: 0.5rem;
                text-align: center;
                background-color: #f5f5f5;
                border-radius: 0.05rem;
                margin: 0 0.1rem;
                color: #e04f29;
                font-size: 0.3rem;
            }

            .read {
                color: #e04f29;
            }
        }

        .code-search {
            background-color: #ffffff;
            width: 100%;
            height: 1rem;
            display: flex;
            justify-content: center;
            align-items: center;

            .input {
                width: 5.12rem;
                height: 0.6rem;
                background-color: #f5f5f5;
                box-sizing: border-box;
                padding: 0 0.18rem;
                border-radius: 0.05rem;
                margin-right: 0.25rem;
            }

            span {
                color: #e04f29;
                font-size: 0.28rem;
            }
        }

        .count-box {
            display: flex;
            justify-content: space-around;
            align-items: center;
            width: 100%;
            height: 1.46rem;
            background-color: #ffffff;
            margin-top: 0.13rem;
            position: relative;

            ::after {
                content: "";
                position: absolute;
                width: 0.02rem;
                height: 0.5rem;
                background-color: #e8e8e8;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                margin: auto;
            }

            .item {
                text-align: center;

                .name {
                    color: #4f4f4f;
                    margin-bottom: 0.2rem;

                }

                .info {
                    color: #e04f29;
                    font-size: 0.24rem;

                    span {
                        font-size: 0.36rem;
                    }
                }
            }
        }

        .tabbar {
            width: 100%;
            height: calc(100vh - 2.79rem);
            overflow-y: auto;
            background: #f6f6f6;
            padding: 0.24rem;
            padding-bottom: 0;
            box-sizing: border-box;
        }

        .tabbar-h-1 {
            height: calc(100vh - 3.79rem);
        }

        .tabbar-h-2 {
            height: calc(100vh - 2.2rem);
        }
    }
</style>
